<template>
  <div class="width-100 height-100 content">
    <!-- <div class="audio_box none">
      <audio ref="audio1" src="https://res.idtcdn.com/Cube/video-config/con-46a8e87e.mp3"></audio>
      <audio ref="audio2" src="https://res.idtcdn.com/Cube/video-config/con-770eb442.mp3"></audio>
      <audio ref="audio3" src="https://res.idtcdn.com/Cube/video-config/con-e4acdab7.mp3"></audio>
      <audio ref="audio4" src="https://res.idtcdn.com/Cube/video-config/con-cb25aee5.mp3"></audio>
      <audio ref="audio5" src="https://res.idtcdn.com/Cube/video-config/con-da37143c.mp3"></audio>
      <audio ref="audio6" src="https://res.idtcdn.com/Cube/video-config/con-31be51c0.mp3"></audio>
      <audio ref="audio7" src="https://res.idtcdn.com/Cube/video-config/con-a0e53a04.mp3"></audio>
      <audio ref="audio8" src="https://res.idtcdn.com/Cube/video-config/con-e8cf494e.mp3"></audio>
    </div> -->
    <div class="card1 animate__animated" v-show="currentIndex == 1">
      <div class="text1 none">
        <span class="line"></span>
        <span>行业点睛</span>
      </div>
      <div class="img_box none">
        <!-- <img src="../../assets/img/gj/gj_text.png" alt="" /> -->
        深跌回弹，静待企稳信号明确
      </div>
      <div class="text2 none">
        <div class="txt1">汪海溯_S0880618020011</div>
        <div class="txt2">2022/03/10</div>
      </div>
      <div class="text3 none">内容摘要</div>
      <div class="text4">
        <ul>
          <li class="none">
            <div class="circle1 circle"></div>
            近期市场震荡下挫，上一期行业点睛推荐的黄金股相对抗跌。
          </li>
          <li class="none">
            <div class="circle2 circle"></div>
            从资金流向来看，电源设备、黄金板块获得资金流入。
          </li>
          <li class="none">
            <div class="circle3 circle"></div>
            指数在连续下跌之后，短期技术面有修复性反弹需求，赛道股或有阶段表现。
          </li>
          <li class="none">
            <div class="circle4 circle"></div>
           在俄乌局势尚不明朗，美联储议息会议悬而未决之际，不宜过分恋战。
          </li>
          <li class="none">
            <div class="circle5 circle"></div>
            最恐慌的时刻已经过去，短期静待反弹企稳信号进一步明确。
          </li>
          <li class="none">
            <div class="circle6 circle"></div>
            可以关注受益于稳增长主线的大基建、新基建、数字化等板块。
          </li>
        </ul>
      </div>
      <div class="tips">
        <img src="../../assets/img/gj/gj_tips.png" alt="" />
        <span>记得点下关注，投资少走“弯路”</span>
      </div>
    </div>
    <div
      class="card2 card animate__animated common_box"
      v-show="currentIndex == 2"
    >
      <div class="img_box none">
        <img src="../../assets/img/gj/gj_t_box.png" alt="" />
        <div class="title none" id="title">一周市场资金动态</div>
      </div>
      <div class="content_box">
        <div class="title1 none" id="title1">1.近期两市成交额（亿元）</div>
        <bar-chart ref="refBarChart1" id="refBarChart1" customClass="c_bar1">
        </bar-chart>
        <div class="title2 chart2 none">2.近期涨跌停家数（家）</div>
        <div class="le_title none">
          <div class="le1">
            <span class="squr"></span>
            <span>涨停</span>
          </div>
          <div class="le2">
            <span class="squr squr2"></span>
            <span>跌停</span>
          </div>
        </div>
        <div class="chart3" id="chart3" ref="chart3"></div>
      </div>
    </div>
    <div
      class="card3 card animate__animated common_box"
      v-show="currentIndex == 3"
    >
      <div class="img_box none">
        <img src="../../assets/img/gj/gj_t_box.png" alt="" />
        <div class="title none" id="title2">市场估值水平概况</div>
      </div>
      <div class="content_box">
        <div class="title1 none" id="title1">1.全部A股平均市盈率</div>
        <div class="leng leng1 none">
          <div class="left">
            <div class="img_box1">
              <img src="../../assets/img/gj/gj_icon1.png" alt="" />
              全部A股平均市盈率
            </div>
          </div>
          <div class="right">
            <div class="img_box2">
              <img src="../../assets/img/gj/gj_icon2.png" alt="" />
              历史中位数
            </div>
            <div class="img_box3">
              <img src="../../assets/img/gj/gj_icon3.png" alt="" />
              合理估值上限
            </div>
            <div class="img_box4">
              <img src="../../assets/img/gj/gj_icon4.png" alt="" />
              合理估值下限
            </div>
          </div>
        </div>
        <div class="chart4" id="chart4"></div>
        <div class="line line_red"></div>
        <div class="line line_blue none"></div>
        <div class="line line_green none"></div>
        <div class="title2 chart2 none">2.沪深300市盈率</div>
        <div class="leng leng2 none">
          <div class="left">
            <div class="img_box1">
              <img src="../../assets/img/gj/gj_icon5.png" alt="" />
              沪深300市盈率
            </div>
          </div>
          <div class="right">
            <div class="img_box2">
              <img src="../../assets/img/gj/gj_icon2.png" alt="" />
              历史中位数
            </div>
            <div class="img_box3">
              <img src="../../assets/img/gj/gj_icon3.png" alt="" />
              合理估值上限
            </div>
            <div class="img_box4">
              <img src="../../assets/img/gj/gj_icon4.png" alt="" />
              合理估值下限
            </div>
          </div>
        </div>
        <div class="chart5" id="chart5"></div>
        <div class="line line_red2"></div>
        <div class="line line_blue2"></div>
        <div class="line line_green2"></div>
      </div>
    </div>
    <div
      class="card4 card animate__animated common_box"
      v-show="currentIndex == 4"
    >
      <div class="img_box none">
        <img src="../../assets/img/gj/gj_t_box.png" alt="" />
        <div class="title none" id="title2">市场估值水平概况</div>
      </div>
      <div class="content_box">
        <div class="title1 none" id="title1">3.科创板市盈率</div>
        <div class="leng leng1 none">
          <div class="left">
            <div class="img_box1">
              <img src="../../assets/img/gj/gj_icon1.png" alt="" />
              科创板市盈率市盈率
            </div>
          </div>
          <div class="right">
            <div class="img_box2">
              <img src="../../assets/img/gj/gj_icon2.png" alt="" />
              历史中位数
            </div>
            <div class="img_box3">
              <img src="../../assets/img/gj/gj_icon3.png" alt="" />
              合理估值上限
            </div>
            <div class="img_box4">
              <img src="../../assets/img/gj/gj_icon4.png" alt="" />
              合理估值下限
            </div>
          </div>
        </div>
        <div class="chart6_1" id="chart6_1"></div>
        <div class="line line_red"></div>
        <div class="line line_blue none"></div>
        <div class="line line_green none"></div>
        <div class="title2 chart2 none">4.创业板平均市盈率</div>
        <div class="leng leng2 none">
          <div class="left">
            <div class="img_box1">
              <img src="../../assets/img/gj/gj_icon5.png" alt="" />
              创业板平均市盈率
            </div>
          </div>
          <div class="right">
            <div class="img_box2">
              <img src="../../assets/img/gj/gj_icon2.png" alt="" />
              历史中位数
            </div>
            <div class="img_box3">
              <img src="../../assets/img/gj/gj_icon3.png" alt="" />
              合理估值上限
            </div>
            <div class="img_box4">
              <img src="../../assets/img/gj/gj_icon4.png" alt="" />
              合理估值下限
            </div>
          </div>
        </div>
        <div class="chart6" id="chart6"></div>
        <div class="line line_red2"></div>
        <div class="line line_blue2"></div>
        <div class="line line_green2"></div>
      </div>
    </div>
    <div
      class="card5 card animate__animated common_box"
      v-show="currentIndex == 5"
    >
      <div class="img_box none">
        <img src="../../assets/img/gj/gj_t_box.png" alt="" />
        <div class="title none" id="title4">行业估值水平观察</div>
      </div>
      <div class="content_box">
        <div class="title1 none" id="title5">1.行业估值</div>
        <div class="len none">
          <span class="len_icon"></span>
          估值合理区间
        </div>
        <div class="len2 none ">
          <span class="len_icon2"></span>
          市盈率TTM最新
        </div>
        <bar-range-chart ref="refBarRangeChart1" id="refBarRangeChart1" customClass="c_bar1" />
        <!-- <div class="chart7" id="chart7"></div> -->
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep } from "@/utils/common";
import barChart from "@/components/barChart/index.vue";
import barRangeChart from "./barRangeChart/index.vue";
export default {
  name: "OneComponent",
  data: function () {
    return {
      currentIndex: 5,
      alphabet: [
        { letter: "03-04", frequency: 10073.53 },
        { letter: "03-07", frequency: 10254.77 },
        { letter: "03-08", frequency: 11108.78 },
        { letter: "03-09", frequency: 11626.37 },
        { letter: "03-10", frequency: 10793.90 },
      ],
      data1: [
        { label: "传媒", value1:19.2, value2: 59.35,value:39.7 },
        { label: "电气设备", value1: 31, value2: 49.2,value:40.23 },
        { label: "电子", value1: 30, value2: 63.25,value:43.4 },
        { label: "汽车", value1: 18.87, value2: 30,value:24.53 },
        { label: "纺织服装", value1: 28, value2: 33.54 ,value:29.09},
        { label: "非银金融", value1: 10, value2:26,value:12.68 },
        { label: "有色金属", value1:28.59, value2: 73.56,value:28.94 },
        { label: "公用事业", value1: 18, value2: 19,value:18.74 },
         { label: "国防军工", value1: 28, value2: 123.5,value:73.27 },
        { label: "化工", value1: 18, value2: 29,value:20.17 },
       { label: "医药生物", value1: 30, value2: 43,value:39.64 },
       { label: "计算机", value1: 39.73, value2: 78.85,value:62.07 },
      ],
    };
  },
  components: { barChart,barRangeChart },
  props: ["cardData"],
  computed: {},
  methods: {
    playIn(inEffect, outEffect, dom) {
      const _outEffect = outEffect == undefined ? "fadeOut" : outEffect;
      this.txt = null;
      this.txt2 = null;
      this.txt = anyElement({
        targets: document.querySelector(dom),
        isSplitTxt: true,
        duration: 1200,
        delay: 60,
        inEffect: inEffect,
        outEffect: _outEffect,
      });
      this.txt.goIn.restart();
    },
    playOut() {
      this.txt.goOut.restart();
    },
     playInRange(refName) {
      this.$refs[refName].BarRangeChart(this.data1, {
        label: (d) => d.label,
        valueLow: (d) => d.value1,
        valueHigh: (d) => d.value2,
        value: (d) => d.value,
        width: 800,
        height: 1200,
        marginLeft: 120,
        marginRight: 80,
        marginBottom: 100,
        duration: 400,
        delay: 400,
        yPadding: 0.95,
        ease: "easeBack",
        xTickNumber: 4,
      });
    },
    playIn1(refName) {
      this.$refs[refName].BarChart(this.alphabet, {
        x: (d) => d.letter,
        y: (d) => d.frequency,
        yLabel: "亿元",
        width: 700,
        height: 350,
        marginLeft: 60,
        yDomain: [9000, 11700],
        duration: 600,
        xPadding: 0.48,
        delay: 60,
        ease: "easeBack",
      });
    },
    // 页面card一个接一个播放
    async playCardStart() {
      this.currentIndex = 1;
      await this.playPage1();
      this.currentIndex = 2;
      await this.playPage2();
      this.currentIndex = 3;
      await this.playPage3();
      this.currentIndex = 4;
      await this.playPage4();
      this.currentIndex = 5;
      this.playPage5();
    },
    async playPage1() {
      // this.$refs.audio1.play();
      $(".text1").removeClass("none");
      $(".text1").addClass("animate__animated animate__fadeIn");
      await sleep(1000);
      $(".img_box").removeClass("none");
      $(".img_box").addClass("animate__animated animate__fadeIn");
      await sleep(1000);
      $(".text2").removeClass("none");
      $(".text2").addClass("animate__animated animate__fadeIn");
      await sleep(1000);
      $(".text3").removeClass("none");
      $(".text3").addClass("animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".text4 li").eq(0).removeClass("none");
      $(".text4 li").eq(0).addClass("animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".text4 li").eq(1).removeClass("none");
      $(".text4 li").eq(1).addClass("animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".text4 li").eq(2).removeClass("none");
      $(".text4 li").eq(2).addClass("animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".text4 li").eq(3).removeClass("none");
      $(".text4 li").eq(3).addClass("animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".text4 li").eq(4).removeClass("none");
      $(".text4 li").eq(4).addClass("animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".text4 li").eq(5).removeClass("none");
      $(".text4 li").eq(5).addClass("animate__animated animate__fadeInLeft");
      await sleep(2000);
      $(".card1").addClass("animate__animated animate__fadeOut");
    },
    async playPage2() {
      // this.$refs.audio2.play();
      $(".card2 .img_box").removeClass("none");
      $(".card2 .img_box").addClass(" animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".card2 .title").removeClass("none");
      this.playIn("fadeIn", "fadeOut", "#title");
      await sleep(1000);
      $(".card2 .title1").removeClass("none");
      $(".card2 .title1").addClass(" animate__animated animate__fadeInLeft");
      await sleep(1000);
      this.playIn1("refBarChart1");
      await sleep(2000);
      // this.$refs.audio3.play();
      $(".card2 .title2").removeClass("none");
      $(".card2 .title2").addClass(" animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".card2 .le_title").removeClass("none");
      $(".card2 .le_title").addClass(" animate__animated animate__fadeInLeft");
      await sleep(1000);
      this.loadChart3();
      await sleep(3000);
      $(".card2").addClass("animate__animated animate__fadeOut");
    },
    async playPage3() {
      // this.$refs.audio4.play();
      $(".card3 .img_box").removeClass("none");
      $(".card3 .img_box").addClass(" animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".card3 .title").removeClass("none");
      this.playIn("fadeIn", "fadeOut", "#title2");
      await sleep(1000);
      $(".card3 .title1").removeClass("none");
      $(".card3 .title1").addClass(" animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".card3 .leng1").removeClass("none");
      $(".card3 .leng1").addClass(" animate__animated animate__fadeIn");
      await sleep(1000);
      this.loadChart4();
      await sleep(1000);
      $(".card3 .line_red").removeClass("none");
      $(".card3 .line_red").animate({ width: "540px" }, 1000);
      await sleep(1000);
      $(".card3 .line_blue").removeClass("none");
      $(".card3 .line_blue").animate({ width: "540px" }, 1000);
      await sleep(1000);
      $(".card3 .line_green").removeClass("none");
      $(".card3 .line_green").animate({ width: "540px" }, 1000);
      await sleep(1000);
      $(".card3 .title2").removeClass("none");
      $(".card3 .title2").addClass(" animate__animated animate__fadeInLeft");
      await sleep(1000);
      // this.$refs.audio5.play();
      $(".card3 .leng2").removeClass("none");
      $(".card3 .leng2").addClass(" animate__animated animate__fadeInLeft");
      await sleep(1000);
      this.loadChart5();
      await sleep(1000);
      $(".card3 .line_red2").removeClass("none");
      $(".card3 .line_red2").animate({ width: "540px" }, 1000);
      await sleep(1000);
      $(".card3 .line_blue2").removeClass("none");
      $(".card3 .line_blue2").animate({ width: "540px" }, 1000);
      await sleep(1000);
      $(".card3 .line_green2").removeClass("none");
      $(".card3 .line_green2").animate({ width: "540px" }, 1000);
      await sleep(2000);
      $(".card3").addClass("animate__animated animate__fadeOut");
    },
    async playPage4() {
      // this.$refs.audio6.play();
      $(".card4 .img_box").removeClass("none");
      $(".card4 .img_box").addClass(" animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".card4 .title").removeClass("none");
      this.playIn("fadeIn", "fadeOut", "#title2");
      await sleep(1000);
      $(".card4 .title1").removeClass("none");
      $(".card4 .title1").addClass(" animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".card4 .leng1").removeClass("none");
      $(".card4 .leng1").addClass(" animate__animated animate__fadeIn");
      await sleep(1000);
      this.loadChart6_1();
      await sleep(1000);
      $(".card4 .line_red").removeClass("none");
      $(".card4 .line_red").animate({ width: "540px" }, 1000);
      await sleep(1000);
      $(".card4 .line_blue").removeClass("none");
      $(".card4 .line_blue").animate({ width: "540px" }, 1000);
      await sleep(1000);
      $(".card4 .line_green").removeClass("none");
      $(".card4 .line_green").animate({ width: "540px" }, 1000);
      await sleep(1000);
      // this.$refs.audio7.play();
      $(".card4 .title2").removeClass("none");
      $(".card4 .title2").addClass(" animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".card4 .leng2").removeClass("none");
      $(".card4 .leng2").addClass(" animate__animated animate__fadeInLeft");
      await sleep(1000);
      this.loadChart6();
      await sleep(1000);
      $(".card4 .line_red2").removeClass("none");
      $(".card4 .line_red2").animate({ width: "540px" }, 1000);
      await sleep(1000);
      $(".card4 .line_blue2").removeClass("none");
      $(".card4 .line_blue2").animate({ width: "540px" }, 1000);
      await sleep(1000);
      $(".card4 .line_green2").removeClass("none");
      $(".card4 .line_green2").animate({ width: "540px" }, 1000);
      await sleep(2000);
      $(".card4").addClass("animate__animated animate__fadeOut");
    },
    async playPage5() {
      // this.$refs.audio8.play();
      $(".card5 .img_box").removeClass("none");
      $(".card5 .img_box").addClass(" animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".card5 #title4").removeClass("none");
      this.playIn("fadeIn", "fadeOut", "#title4");
      // await sleep(1000);
      $(".card5 #title5").removeClass("none");
      this.playIn("fadeIn", "fadeOut", "#title5");
      // await sleep(1000);
      $(".card5 .len").removeClass("none");
      $(".card5 .len").addClass(" animate__animated animate__fadeInRight");
      $(".card5 .len2").removeClass("none");
      $(".card5 .len2").addClass(" animate__animated animate__fadeInRight");
      await sleep(1000);
      // this.loadChart7();
      this.playInRange('refBarRangeChart1')
      await sleep(9000);
      $(".card5").addClass("animate__animated animate__fadeOut");
      this.$emit("playEnd");
    },

    loadChart3() {
      let myChart = this.$echarts.init(document.getElementById("chart3"));
      let option = (option = {
        grid: {
          left: "50",
          right: "50",
          bottom: "50",
          top: "20",
        },
        xAxis: {
          type: "category",
          // boundaryGap: [0, 0.01],
          data: ["03-04", "03-07", "03-08", "03-09", "03-10"],
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            fontSize: 13,
            color: "#000",
          },
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          position: "left",
          splitLine: {
            show: false,
            lineStyle: {
              color: "#3D3D3D",
              type: "dashed",
            },
          },
          axisLabel: {
            show: true,
            fontSize: 13,
            color: "#000",
            formatter: function (value) {
              return value.toFixed(2);
            },
          },
        },
        series: [
          {
            name: "vtDateValue",
            type: "bar",
            data: [42, 49, 23, 47, 84],
            itemStyle: {
              color: "#5B8FF9",
            },
          },
          {
            name: "vtDateValueAvg",
            type: "bar",
            data: [15, 10, 56, 9 ,4],
            itemStyle: {
              color: "#5AD8A6",
            },
          },
        ],
      });
      myChart.setOption(option);
    },
    loadChart4() {
      let myChart = this.$echarts.init(document.getElementById("chart4"));
      let option = (option = {
        xAxis: {
          type: "category",
          data: [
            "2016/01/07",
            "2016/09/01",
            "2017/05/04",
            "2017/12/28",
            "2018/08/16",
            "2019/04/18",
            "2019/12/12",
            "2020/07/30",
            "2021/03/25",
            "2021/11/18",
            "2022/02/17",
          ],
          axisTick: {
            show: false,
          },
        },
        grid: {
          bottom: 30,
          top: 10,
          left: 30,
          right: 30,
        },
        yAxis: {
          type: "value",
          show: true,
          min: 10,
          axisLabel: {
            show: true,
            color: "#8C8C8C",
            fontSize: 13,
          },
          position: "left",
          splitLine: {
            lineStyle: {
              color: "#D9D9D9",
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#707070",
            },
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            name: "TTM1",
            data: [
              18.37,18.41,17.93,17.63,13.27,15.83,15.63,19.12,18.12,16.94,16.54,15.27
            ],
            type: "line",
            symbol: "none",
            itemStyle: {
              color: "#FFAD01",
              lineStyle: {
                width: 2,
              },
            },
          },
        ],
      });
      myChart.setOption(option);
    },
    loadChart5() {
      let myChart = this.$echarts.init(document.getElementById("chart5"));
      let option = (option = {
        xAxis: {
          type: "category",
          data: [
            "2016/01/07",
            "2016/09/01",
            "2017/05/04",
            "2017/12/28",
            "2018/08/16",
            "2019/04/18",
            "2019/12/12",
            "2020/07/30",
            "2021/03/25",
            "2021/11/18",
            "2022/02/17",
          ],
          axisTick: {
            show: false,
          },
        },
        grid: {
          bottom: 30,
          top: 20,
          left: 30,
          right: 30,
        },
        yAxis: {
          type: "value",
          show: true,
          min: 10,
          axisLabel: {
            show: true,
            color: "#8C8C8C",
            fontSize: 13,
          },
          position: "left",
          splitLine: {
            lineStyle: {
              color: "#D9D9D9",
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#707070",
            },
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            name: "TTM1",
            data: [
              12.18,13.25,12.92,14.39,11.11,12.58,12.52,14.04,15.13,13.37,12.45,
            ],
            type: "line",
            symbol: "none",
            itemStyle: {
              color: "#1890FF",
              lineStyle: {},
            },
          },
        ],
      });
      myChart.setOption(option);
    },
    loadChart6_1() {
      let myChart = this.$echarts.init(document.getElementById("chart6_1"));
      let option = (option = {
        xAxis: {
          type: "category",
          data: [
            "2019/07/25",
            "2019/10/24",
            "2020/01/09",
            "2020/04/09",
            "2020/07/02",
            "2020/09/24",
            "2020/12/24",
            "2021/03/18",
            "2021/06/10",
            "2021/09/20",
            "2021/12/02",
            "2022/03/03",
          ],
          axisTick: {
            show: false,
          },
        },
        grid: {
          bottom: 30,
          top: 20,
          left: 30,
          right: 30,
        },
        yAxis: {
          type: "value",
          show: true,
          min: 15,
          axisLabel: {
            show: true,
            color: "#8C8C8C",
            fontSize: 13,
          },
          position: "left",
          splitLine: {
            lineStyle: {
              color: "#D9D9D9",
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#707070",
              lineStyle: {},
            },
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            name: "TTM1",
            data: [
              38.73,34.3,36.73,39.72,41.25,77.53,65,63,60,59,56,46.77,
            ],
            type: "line",
            symbol: "none",
            itemStyle: {
              color: "#FFAD01",
              lineStyle: {},
            },
          },
        ],
      });
      myChart.setOption(option);
    },
    loadChart6() {
      let myChart = this.$echarts.init(document.getElementById("chart6"));
      let option = (option = {
        xAxis: {
          type: "category",
          data: [
            "2016/01/07",
            "2016/09/01",
            "2017/05/04",
            "2017/12/28",
            "2018/08/16",
            "2019/04/18",
            "2019/12/12",
            "2020/07/30",
            "2021/03/25",
            "2021/11/18",
            "2022/02/17",
          ],
          axisTick: {
            show: false,
          },
        },
        grid: {
          bottom: 30,
          top: 10,
          left: 30,
          right: 30,
        },
        yAxis: {
          type: "value",
          show: true,
          min: 34,
          axisLabel: {
            show: true,
            color: "#8C8C8C",
            fontSize: 13,
          },
          position: "left",
          splitLine: {
            lineStyle: {
              color: "#D9D9D9",
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#707070",
              lineStyle: {},
            },
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            name: "TTM1",
            data: [68.37,53.45,43.57,42.56,37.53,41.23,39.45,42.34,41.57,58.53,43.64],
            type: "line",
            symbol: "none",
            itemStyle: {
              color: "#1890FF",
            },
          },
        ],
      });
      myChart.setOption(option);
    },
    loadChart7() {
      let myChart = this.$echarts.init(document.getElementById("chart7"));
      let optionData = {
        label: ["传媒","",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",],
        Placeholder: [330],
        Income: [180],
        label2:["传媒",
          "电气设备",
          "电子",
          "房地产",
          "纺织服装",
          "非银金融",
          "钢铁",
          "公用事业",
          "国防军工",
          "化工",
          "机械设备",
          "汽车",],
        Placeholder2: [
          19, 580, 943, 429, 295, 759, 377, 688, 855, 494, 791, 599, 
        ],
        Income2: [
          58, 150, 125, 180, 170, 160, 180, 190, 150, 110, 160, 130, 
        ],
      };
      let option = (option = {
        grid: {
          left: "3%",
          right: "6%",
          bottom: "3%",
          containLabel: true,
        },
        animationDuration: 300,
        animationDurationUpdate: 300,
        animationEasing: "linear",
        animationEasingUpdate: "linear",
        yAxis: {
          type: "category",
          show: true,
          inverse: true,
          data: optionData.label,
          animationDuration: 300,
          animationDurationUpdate: 300,
          axisLabel: {
            show: true,
            align:"left",
            color: "#333333",
            fontSize: 24,
            padding:-110
          },
        },
        xAxis: {
          type: "value",
          splitNumber: 4,
          axisLabel: {
            show: true,
            color: "#8C8C8C",
            fontSize: 13,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#eee",
            },
          },
        },
        series: [
          {
            name: "Placeholder",
            type: "bar",
            stack: "Total",
            itemStyle: {
              borderColor: "transparent",
              color: "transparent",
            },
            animation: false,
            label: {
              show: true,
              fontSize: 16,
              color: "#2A4DCF",
              position: "right",
              distance: -50,
            },
            barWidth: 30,
            emphasis: {
              itemStyle: {
                borderColor: "transparent",
                color: "transparent",
              },
            },
            data: optionData.Placeholder,
          },
          {
            name: "Income",
            type: "bar",
            stack: "Total",
            itemStyle: {
              borderColor: "#FFAD01",
              color: "#FFAD01",
            },
            label: {
              show: true,
              fontSize: 16,
              distance: 20,
              color: "#2A4DCF",
              position: "right",
              formatter: function (params) {
                return optionData.Placeholder[params.dataIndex] + params.value;
              },
            },
            data: optionData.Income,
          },
        ],
      });
      myChart.setOption(option);
      // 动画一个个的刷数据
      let refreshIdx = 1;
      let timer = setInterval(() => {
        if (refreshIdx >= optionData.Placeholder2.length) {
          clearInterval(timer);
        } else {
          optionData.label[refreshIdx]=optionData.label2[refreshIdx];
          optionData.Placeholder.push(optionData.Placeholder2[refreshIdx]);
          optionData.Income.push(optionData.Income2[refreshIdx]);
          myChart.setOption({
            yAxis:{
              data: optionData.label,
            },
            series: [
              {
                name: "Placeholder",
                type: "bar",
                data: optionData.Placeholder,
              },
              {
                name: "Income",
                type: "bar",
                data: optionData.Income,
              },
            ],
          });
          refreshIdx++;
        }
      }, 300);

    },
  },
};
</script>
<style scoped lang="less">
@import "./one.less";
</style>

